import styled, { keyframes } from 'styled-components';
import Card from 'react-bootstrap/Card';

export const StyledCard = styled(Card)`
  border: 0.7px solid #ffb900;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-left: auto;
  margin-right: auto;
  background: #fff !important;
  padding: 10px;
  //font-size: 16.8px;
`;
export const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.mediumRedViolet};
  text-align: center;
`;

export const Content = styled.p`
  text-align: center;
  margin-bottom: 3rem;
`;
export const StyledCardText = styled.div`
  text-align: center;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
`;
//royalBlue jungleGreen
export const CardTitle = styled.h2`
  margin-top: 0.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;
export const Title = styled.h2`
  letter-spacing: 7px;
  text-align: center;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;

export const StyledImageArea = styled.div`
  display: flex;
  justify-content: center;
`;
export const StyledParagraph = styled.p`
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`;
export const StyledButtonPosition = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ProductSlideShowWrapper = styled.div`
  min-height: 80vh;
  height: 100%;
  margin-bottom: 10rem;
`;

const bg = keyframes`
0%{
opacity: 1;
}
25%{
opacity: .7;
}
50%{
opacity: .5;
}
75%{
opacity: .7;
}
100%{
opacity: 1;
}
`;
const colorGWoS = keyframes`
0%{
color: #fff;
}
25%{
color: #ffff80;
}
50%{
color:#ffff00;
}
75%{
color: #fff;
}
100%{
color: #fff;
}
`;
export const FeatureContainer = styled.div`
  position: relative;
  display: flex;
  alignitems: stretch;
  @media (max-width: 600px) {
    display: block;
    alignitems: center !important;
  }
`;
export const Box1 = styled.div`
  position: absolute;
  zindex: 3;
  bottom: auto;
  top: 0;
  height: 100%;
  width: 40%;
  background: repeating-linear-gradient(-45deg, #5cd6d6, #29a3a3 2%);
  clip-path: polygon(0% 100%, 0% 0%, 50% 0%, 100% 50%, 50% 100%);
  opacity: 1;
  animation: ${bg} 5s linear infinite;
  @media (max-width: 600px) {
    background: repeating-linear-gradient(-45deg, #5cd6d6, #29a3a3 4%);
    clip-path: polygon(0% 50%, 0% 0%, 100% 0%, 100% 50%, 50% 100%);
    position: relative !important;
    width: 100%;
    top: auto;
  }
`;

export const FeatureText = styled.div`
  z-index: 5;
  margin-left: 30%;
  right: auto;
  bottom: 0;
  top: 0;

  @media (max-width: 600px) {
    margin-left: auto;
    right: auto;
    bottom: auto;
    top: auto;
  }
`;

export const FeatureParagraph = styled.div`
  margin-bottom: 2rem;
`;

export const CenterGWOS = styled.div`
  position: absolute;
  top: 50%;
  left: 40%;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  color: #fff;
  font-size: 5rem;
  text-shadow: 0.7px 1px #000000;
  animation: ${colorGWoS} 5s linear infinite;

  @media (max-width: 600px) {
    position: relative !important;
    height: auto;
    transform: none !important;
    -ms-transform: none !important;
    font-size: 3rem;
    top: auto !important;
    left: auto !important;
    text-align: center !important;
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
`;
