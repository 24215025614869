import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import ReactAudioPlayer from 'react-audio-player';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Row } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Audio from '../../images/audio.mp3';
import ModalImageGallery from '../modals/modal-image-gallery';
import { Title2 } from './style';
const AwardWinners = () => {
  const [showModalGallery, setShowModalGallery] = useState(false);
  const handleCloseModalGallery = () => setShowModalGallery(false);
  const handleShowModalGallery = () => setShowModalGallery(true);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [subTitle, setSubtitle] = useState(null);
  const [alt, setAlt] = useState(null);
  const data = useStaticQuery(graphql`
    {
      allContentfulAwardWinners {
        edges {
          node {
            id
            title
            imagesTop {
              title
              containerImages {
                gatsbyImageData(width: 600, height: 650, quality: 90)
              }
            }
            images {
              image {
                title
                gatsbyImageData
              }
            }
            videos {
              id
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);
  const path = window.location.pathname; // "/app/awards/2024"
  const lastSegment = path.split('/').filter(Boolean).pop(); // "2024"
  console.log(data, 'data_award_winners');
  return (
    <Container className="flex justify-center">
      <ModalImageGallery
        showModalGallery={showModalGallery}
        handleCloseModalGallery={handleCloseModalGallery}
        image={image}
        title={title}
        subtitle={subTitle}
        alt={alt}
      />
      <div style={{ display: 'none' }}>
        {/* <ReactAudioPlayer src={Audio} autoPlay controls loop /> */}
      </div>
      <Row>
        <div className="p-4">
          <h3 className="text-center">
            <strong>
              {data?.allContentfulAwardWinners?.edges[lastSegment === '2024' ? 0 : 1]?.node?.title}
            </strong>
          </h3>
        </div>
      </Row>
      <Row className="flex justify-center">
        {data?.allContentfulAwardWinners?.edges[lastSegment === '2024' ? 0 : 1]?.node
          ?.videos[0] && (
          <Col
            xs={12}
            md={12}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Carousel infiniteLoop width={900} height={500} interval={40000}>
              {data?.allContentfulAwardWinners.edges[lastSegment === '2024' ? 0 : 1].node.videos &&
                data?.allContentfulAwardWinners.edges[
                  lastSegment === '2024' ? 0 : 1
                ].node.videos.map((e, i) => (
                  <div key={i}>
                    {e.image.file.url !== undefined ? (
                      <video
                        src={e.image.file.url} // URL to your MP4 video
                        controls
                        autoPlay
                        muted
                        loop
                        alt={e.subtitle || 'Video'}
                        style={{ width: '100%', height: '100%' }}
                      />
                    ) : null}
                  </div>
                ))}
            </Carousel>
          </Col>
        )}
        <Col xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Carousel autoPlay infiniteLoop width={650} interval={4000}>
            {data?.allContentfulAwardWinners.edges[lastSegment === '2024' ? 0 : 1]?.node?.images &&
              data?.allContentfulAwardWinners.edges[
                lastSegment === '2024' ? 0 : 1
              ]?.node?.images.map((e, i) => (
                <div key={i}>
                  {e.image !== undefined ? (
                    <GatsbyImage
                      image={getImage(e.image !== undefined ? e.image.gatsbyImageData : null)}
                      alt={e.subtitle !== null || e.subtitle !== undefined ? `${e.subtitle}` : null}
                    />
                  ) : null}
                </div>
              ))}
          </Carousel>
        </Col>
      </Row>
      {/* All Images */}
      <Row>
        {data?.allContentfulAwardWinners?.edges[lastSegment === '2024' ? 0 : 1]?.node?.imagesTop &&
          data?.allContentfulAwardWinners?.edges[
            lastSegment === '2024' ? 0 : 1
          ]?.node?.imagesTop[0]?.containerImages?.map((img, i) => {
            return (
              <Col xs={12} sm={6} md={4} lg={3} xl={3} className="my-2" key={i}>
                <>
                  <div
                    onClick={async () => {
                      await setImage(img.gatsbyImageData);
                      await setTitle(img.title);
                      await setSubtitle(img.title);
                      await setAlt(img.title);
                      await handleShowModalGallery();
                    }}
                    onKeyDown={handleShowModalGallery}
                    className="imageGalleryContainer"
                    role="button"
                    aria-hidden>
                    <div className="galleryImage">
                      <GatsbyImage
                        style={{ minHeight: '350px' }}
                        image={getImage(img !== undefined ? img.gatsbyImageData : null)}
                        alt={img.title !== null || img.title !== undefined ? `${img.title}` : null}
                      />
                    </div>

                    <div className="imageMagnifyingGlass"></div>
                  </div>
                </>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};
export default AwardWinners;
