import { Router } from '@reach/router';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import AwardCategories from '../components/awardCategories';
import AwardNight from '../components/awardNight';
import BookTickets from '../components/bookTicket';
import Default from '../components/Default';
import Gallery from '../components/Gallery';
import GallerySingle from '../components/Gallery/gallerySingle';
import Chapel from '../components/GwosChapel';
import Layout from '../components/Layout';
import PrivateRoute from '../components/privateRouter';
import Resources from '../components/Resources';
import { theme } from '../styles/theme';
import ErrorAppComponent from './404.error.app';
import AwardWinners from '../components/AwardWinners';
import GlobalPrayerWalk from '../components/GlobalPrayerWalk';
import loadable from '@loadable/component';
const EmpoweringWomen = loadable(() => import('../components/donate/donate-main'));
export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      {/*<GlobalStyle />*/}

      <Layout>
        {
          <Router basepath="/app">
            <Gallery path="/gallery" />
            <GallerySingle path="/gallery/:titlle" />
            <Chapel path="/chapel" />
            <EmpoweringWomen path="/empowering-women" />
            <GlobalPrayerWalk path="/global-prayer-walk" />
            <BookTickets path="/book-tickets" />
            <AwardNight path="/award-night" />
            <AwardCategories path="/nominations" />
            <AwardWinners path="awards/2024" />
            <AwardWinners path="awards/2023" />
            <PrivateRoute path="/resources" component={Resources} />
            <Default path="/" />
            <ErrorAppComponent default />
          </Router>
        }
      </Layout>
    </ThemeProvider>
  );
};

export default App;
